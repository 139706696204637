import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ActiveState } from 'src/app/shared/models/active-states.model';
import { FileLocation } from 'src/app/shared/models/filelocation.model';
import { Race } from 'src/app/shared/models/race.model';
import { StatesService } from 'src/app/shared/services/states.service';
import { InternService } from '../../../services/intern.service';
import { StaticTableService } from '../../../services/statictable.service';
import { AccountSource } from '../../../shared/models/accountsource.model';
import { CommunicationType } from '../../../shared/models/communicationType.model';
import { Country } from '../../../shared/models/country.model';
import { Ethnicity } from '../../../shared/models/ethnicity.model';
import { Gender } from '../../../shared/models/gender.model';
import { Address, Email, Intern, PhoneNumber } from '../../../shared/models/intern.model';
import { State } from '../../../shared/models/state.model';
import { Suffix } from '../../../shared/models/suffix.model';
import { MilitaryStatusEnum } from 'src/app/shared/enums/military-status.enum';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss']
})
export class GeneralInfoComponent implements OnInit {
  submitClicked: boolean = false;
  duplicateEmail: boolean = false;
  @Input() set intern_details(value: Intern) {
    this.intern = value;
    if (this.isParentActivated$ && !!this.intern && this.dataloaded === false) {
      this.initialize(this.intern);
    }
  }
  @Input() set isParentActivated(value: boolean) {
    this.isParentActivated$ = value;
    if (this.isParentActivated$ && !!this.intern && this.dataloaded === false) {
      this.initialize(this.intern);

    }

  }
  @Input() editMode: string;

  @Output() lateHireToEmit = new EventEmitter<boolean>();

  constructor(
    private internSvc: InternService,
    private statesService: StatesService,
    private statTblSvc: StaticTableService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private authSvs: AuthService,
    private internService: InternService
  ) { }

  public id: string;
  public intern: Intern = new Intern();
  public saving = false;
  public saved = false;
  public suffixes: Suffix[];
  public genders: Gender[];
  public states: State[];
  public countries: Country[];
  public accountsources: AccountSource[];
  public ethnicitys: Ethnicity[];
  public races: Race[];
  public phonetypes: CommunicationType[];
  public filelocations: FileLocation[];
  emailColumns: string[] = ['primaryradio', 'emailaddress', 'delete'];
  radiogroup: any;
  selectedPhone: PhoneNumber;
  selectedEmail: Email;
  dataloaded = false;
  profilestates = new UntypedFormControl();
  statesList: ActiveState[] = [];
  selectedState: ActiveState;
  internNumber: string;
  private isParentActivated$: boolean = false;
  private _intern_details: Intern = null;

  ngOnInit() {
    if (this.editMode == "new") {
      this.resetIntern();
      this.initialize(this.intern);
    }
  }

  initialize(intern: Intern) {

    this.intern = intern;
    if (this.intern.addresses === null || this.intern.addresses.length === 0) {
      const address: Address = {
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        stateCode: ''
      };
      this.intern.addresses.push(address);
    }
    this.setEmailRadioButton();


    for (const phone of this.intern.phoneNumbers) {
      phone.active = true;
    }
    for (const email of this.intern.emails) {
      email.active = true;
      email.isAuthEmail = this.editMode == "new" ? false : email.emailAddress == this.intern.userEmailAddress;
    }
    this.setPhoneRadioButton();
    this.dataloaded = false;
    this.getActiveStates();

    // get suffixes
    this.statTblSvc.getSuffixes().subscribe(data => {
      this.suffixes = data;
      this.updateLoadingIndicator();
    });

    // get genders
    this.statTblSvc.getGenders().subscribe(data => {
      this.genders = data;
      this.updateLoadingIndicator();
    });

    // get states
    this.statTblSvc.getStates().subscribe(data => {
      this.states = data;
      this.updateLoadingIndicator();
    });

    // get countries
    this.statTblSvc.getCountries().subscribe(data => {
      this.countries = data;
      this.updateLoadingIndicator();
    });

    // get account sources
    this.statTblSvc.getAccountSources().subscribe(data => {
      this.accountsources = data;
      this.updateLoadingIndicator();
    });

    // get ethnicitys
    this.statTblSvc.getEthnicitys().subscribe(data => {
      this.ethnicitys = data;
      this.updateLoadingIndicator();
    });

    // get races
    this.statTblSvc.getRaces().subscribe(data => {
      this.races = data;
      this.updateLoadingIndicator();
    });

    // get phone types
    this.statTblSvc.getPhoneTypes().subscribe(data => {
      this.phonetypes = data;
      this.updateLoadingIndicator();
    });
    // get phone types
    this.statTblSvc.getFileLocations().subscribe(data => {
      this.filelocations = data;
      this.updateLoadingIndicator();
    });
  }

  updateLoadingIndicator() {
    if (!!this.countries && !!this.statesList && !!this.suffixes && !!this.genders
      && !!this.accountsources && !!this.ethnicitys && !!this.races && !!this.phonetypes
      && !!this.filelocations) {
      this.dataloaded = true;
    }
  }

  getInternData(afterSave: boolean = false) {
    if (this.editMode === 'new') {
      this.id = '0';
      this.dataloaded = true;
      this.resetIntern();

      return;
    } else {
      this.getDataById(this.intern.profileId, afterSave);
    }
  }

  private resetIntern() {
    const address: Address[] = [{
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      stateCode: ''
    }];

    const phone: PhoneNumber[] = [{
      id: 0,
      phoneNumber: '',
      extension: '',
      type: 0,
      isPrimary: true,
      active: true,
    }];

    const email: Email[] = [{
      id: 0,
      emailAddress: '',
      isPrimary: true,
      extension: '',
      type: 0,
      active: true
    }];

    let todayDate = new Date();

    this.intern = {
      holdEndDate: null,
      holdStartDate: null,
      financialStatusId: 0,
      internId: '',
      suffix: '',
      id: 0,
      internNumber: '',
      objectId: '',
      lastName: '',
      middleName: '',
      maidenName: '',
      preferredName: '',
      firstName: '',
      gender: '',
      ethnicity: '',
      race: '',
      addresses: address,
      phoneNumbers: phone,
      emails: email,
      emailAddress: '',
      dob: null,
      admitDate: null,
      programPath: this.intern.programPath,
      programPathId: this.intern.programPathId,
      offeredAdmitDate: null,
      offeredAdmitDateExpired: null,
      applicationDate: todayDate,
      paidDate: null,
      ssn: '',
      referralSources: 0,
      isVip: false,
      isExcludedFromHcl: false,
      admittedThreshold: false,
      fileLocation: 0,
      referralIntern: '',
      profileId: 0,
      college: '',
      campus: '',
      district: '',
      status: '',
      statusId: 0,
      state: '',
      stateId: null,
      stateHasConfigurableAgreements: false,
      accessedByYouOn: null,
      city: '',
      educations: null,
      employments: null,
      stateLicenseId: '',
      ruleset: '',
      userEmailAddress: '',
      thirdPartyId: 0,
      doNotEmail: false,
      lateHire: false,
      federalBackgroundCheckDate: null,
      modUser: '',
      createUser: '',
      interviewDate: null,
      profileIdentifier: '',
      trainingPathId: null,
      lateHireExpirationDate: null
    };
  }

  GetGeneralInfo_ByCandidateID(CandidateID, afterSave: boolean = false) {
    this.internSvc.GetGeneralInfoByCandidateID(CandidateID).subscribe(data => {
      this.intern = data;
      if (this.intern.addresses === null || this.intern.addresses.length === 0) {
        const address: Address = {
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          zip: '',
          stateCode: ''
        };
        this.intern.addresses.push(address);
      }
      this.setEmailRadioButton();

      for (const phone of this.intern.phoneNumbers) {
        phone.active = true;
      }
      for (const email of this.intern.emails) {
        email.active = true;
      }
      this.setPhoneRadioButton();

      if (afterSave) this.doAfterSave();

      this.dataloaded = true;
    });
  }


  getDataById(id, afterSave: boolean = false) {
    this.internSvc.getGeneralInfo(id).subscribe(data => {
      this.intern = data;
      if (this.intern.addresses === null || this.intern.addresses.length === 0) {
        const address: Address = {
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          zip: '',
          stateCode: ''
        };
        this.intern.addresses.push(address);
      }
      this.setEmailRadioButton();

      for (const phone of this.intern.phoneNumbers) {
        phone.active = true;
      }
      for (const email of this.intern.emails) {
        email.active = true;
        email.isAuthEmail = email.emailAddress == this.intern.userEmailAddress;
      }
      this.setPhoneRadioButton();

      if (afterSave) this.doAfterSave();

      this.dataloaded = true;
    });
  }

  getActiveStates() {
    this.statesService.getActiveStatesList().subscribe(data => {
      this.statesList = data;
      this.statesList = this.statesList.sort();
    });
  }

  doAfterSave() {
    //debugger;
    if (this.saving && !this.saved) {
      //Things to do after save...
      this.lateHireToEmit.emit(this.intern.lateHire);

      this.saving = false;
      this.saved = true;
    }
  }

  onSubmit(generalInfoForm: NgForm) {

    if (generalInfoForm.invalid) {
      this.snackBar.open('Some form values are incorrect. Please check the form and try again.', 'Okay', { panelClass: ['alert-danger'] });
      return;
    }

    if (this.intern.federalBackgroundCheckDate && (this.intern.federalBackgroundCheckDate > new Date())) {
      this.snackBar.open('Federal Background Check Date cannot be in the future. Please select a valid date.', 'Okay');
      return;
    }
    const primaryEmail = this.intern.emails.find(email => email.isPrimary);
    this.internService.primaryEmailAlreadyExists(primaryEmail.emailAddress).subscribe(value => {
      this.duplicateEmail = value;

      // process if not duplicate email
      this.saving = true;
      this.saved = false;

      if (this.editMode === 'new') {

        this.submitClicked = true;

        if (this.duplicateEmail) {
          this.snackBar.open(`The primary email address '${primaryEmail.emailAddress}' already exists. Please use another email.`, 'Okay', { panelClass: ['alert-danger'] });
          this.submitClicked = false;
          return;
        }

        this.intern.createUser = this.authSvs.user.displayName;
        this.intern.modUser = this.authSvs.user.displayName;

        this.internSvc.post(this.intern).subscribe(data => {
          const candidateId = data;
          this.GetGeneralInfo_ByCandidateID(candidateId, true);
          this.snackBar.open('Changes saved successfully', 'Okay', {
            duration: 3000
          });

          window.setTimeout(() => {
            this.router.navigate(['/intern-search']);
          }, 3000);

        },
          () => {
            this.snackBar.open('There was an error while saving candidate information.', null, {
              duration: 5000
            });
          });
      } else {

        this.intern.modUser = this.authSvs.user.displayName;

        this.internSvc.put(this.intern, this.intern.profileId.toString()).subscribe(() => {
          this.getInternData(true);
          this.snackBar.open('Changes saved successfully', 'Okay', {
            duration: 3000
          });
        },
          () => {
            this.snackBar.open('There was an error while updating candidate information.', null, {
              duration: 5000
            });
          });
      }
    });
  }

  addEmailRow() {
    const email: Email = {
      id: 3,
      emailAddress: '',
      isPrimary: false,
      extension: 'a',
      type: 1,
      active: true
    };
    this.intern.emails.push(email);
  }
  deleteEmailRow(idx) {
    this.intern.emails[idx].active = false;
  }
  emailRadioChanged() {
    if (this.selectedEmail != null) {
      for (const email of this.intern.emails) {
        email.isPrimary = false;
        if (email != null && email.emailAddress === this.selectedEmail.emailAddress) {
          email.isPrimary = true;
        }
      }
    }
  }
  setEmailRadioButton() {
    for (const email of this.intern.emails) {
      if (email != null && email.isPrimary === true) {
        this.selectedEmail = email;
      }
    }
  }

  addPhoneRow() {
    const phone: PhoneNumber = {
      id: 0,
      extension: '',
      phoneNumber: '',
      isPrimary: false,
      type: 1,
      active: true
    }
    this.intern.phoneNumbers.push(phone);
  }
  deletePhoneRow(idx) {
    this.intern.phoneNumbers[idx].active = false;
  }
  phoneRadioChanged() {
    if (this.selectedPhone != null) {
      for (const phone of this.intern.phoneNumbers) {
        phone.isPrimary = false;
        if (phone != null && phone.phoneNumber === this.selectedPhone.phoneNumber) {
          phone.isPrimary = true;
        }
      }
    }
  }
  setPhoneRadioButton() {
    for (const phone of this.intern.phoneNumbers) {
      if (phone != null && phone.isPrimary === true) {
        this.selectedPhone = phone;
      }
    }
  }

  getMilitaryStatus() {
    switch (this.intern.militaryStatusId) {
      case MilitaryStatusEnum.ActiveDutyMilitary:
        return 'Active-Duty Military';
      case MilitaryStatusEnum.Veteran:
        return 'Veteran';
      case MilitaryStatusEnum.MilitarySpouse:
        return 'Military Spouse';
      case MilitaryStatusEnum.NotApplicable:
        return 'Not Applicable';
      default:
        return '';
    }
  }

  cancel() {
    this.submitClicked = false;
    this.getInternData(); // TODO: Consider upgrading this page to use Reactive Forms in a future release
  }

  close() {
    this.router.navigate(['']);
  }
}
