import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, SilentRequest } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import { EMPTY } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import { catchError, shareReplay } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { ActiveDirectoryUserInfo } from '../shared/models/active-directory-userinfo.model';
import { Authorization_GetRoles_Options } from '../shared/models/authorization.model';
import { LoggedinUserInfo } from '../shared/models/loggedin-userinfo.model';
import { PrinceUserInfo } from '../shared/models/prince-userinfo.model';
import { Role, RoleValue } from '../shared/models/role.model';
import { User } from '../shared/models/user';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    adUserInfo: ActiveDirectoryUserInfo;
    princeUserInfo: PrinceUserInfo;
    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    apiUrl = '';
    account: any;
    accessToken: any;
    loggedinUserInfo: LoggedinUserInfo;
    @Output() userLoaded: EventEmitter<User> = new EventEmitter<User>();
    public authenticated = false;
    public hasRoles: boolean = false;
    public user: User;

    constructor(
        private httpClient: HttpClient,
        private msalService: MsalService,
        private configSvc: ConfigService) {
        this.apiUrl = this.configSvc.getApiUrl();
    }

    async initializeUser(account: AccountInfo) {

        this.account = account;


        if (!!this.account) {
            this.user = new User();
            const emailAddress = <string><unknown>this.account.username;
            this.user.email = emailAddress;
            this.user.displayName = <string><unknown>this.account.idTokenClaims.name;
            await this.getUserInfo(emailAddress).toPromise()
                .then(userInfo => {
                    this.user.id = userInfo.UserId;
                    const roles: any[] = userInfo.Roles;
                    this.user.roles = roles.map(r => ({
                        AuthorizationRoleID: r.Id,
                        AuthorizationRoleName: r.Description
                    })
                    );
                })
                .then(() => this.userLoaded.emit(this.user));
            this.hasRoles = !!this.user.roles && this.user.roles.length > 0;
        }
        this.authenticated = !!this.account && !!this.user;

        return this.user;
    }

    async GetUserRoles(emailAddress) {
        const options: Authorization_GetRoles_Options = {
            AuthenticatedEmailAddress: emailAddress
        };
        const userRoles = await this.Authorization_GetRoles(options).toPromise().then(roles => {
            return roles;
        }).catch((error) => {
            console.error(error);
        });
        return userRoles;

    }

    public async GetRoles(user: User) {
        const options: Authorization_GetRoles_Options = {
            AuthenticatedEmailAddress: user.email
        };
        return this.Authorization_GetRoles(options).toPromise();
    }

    //Client method to get roles from Prince database
    public Authorization_GetRoles(Authorization_GetRoles_byEmail: Authorization_GetRoles_Options) {
        return this.httpClient.post<any>(`${this.apiUrl}/v1.0/Authorization/GetRoles`, Authorization_GetRoles_byEmail).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    private getUserInfo(emailAddress: string) {
        return this.httpClient.get<any>(`${this.apiUrl}/userinfo/GetUserInfo?email=${emailAddress}`);
    }

    // Silently request an access token
    public async getAccessToken(): Promise<any> {
        const params: SilentRequest = {
            scopes: this.configSvc.scopes,
            account: this.account
        };
        return new Promise((resolve, reject) => {
            this.msalService.acquireTokenSilent(params).toPromise().then(authResponse => {
                resolve(authResponse.accessToken);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public async getAccessTokenForAPI(): Promise<any> {
        const params: SilentRequest = {
            scopes: this.configSvc.scopeForAPI,
            account: this.account
        };
        return new Promise((resolve, reject) => {
            this.msalService.acquireTokenSilent(params).toPromise().then(authResponse => {
                resolve(authResponse.accessToken);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public async getUser(): Promise<User> {
        if (!this.authenticated) return null;
        let graphClient = Client.init({
            // Initialize the Graph client with an auth
            // provider that requests the token from the
            // auth service
            authProvider: async (done) => {
                let token = await this.getAccessToken()
                    .catch((reason) => {
                        done(reason, null);
                    });

                if (token) {
                    done(null, token);
                } else {
                    done("Could not get an access token", null);
                }
            }
        });

        // Get the user from Graph (GET /me)
        let graphUser = await graphClient.api('/me').get();

        let user = new User();

        if (graphUser != undefined) {
            user.displayName = graphUser.displayName;
            user.surname = graphUser.surname;
            user.id = graphUser.id;
            user.givenName = graphUser.givenName;

            // Prefer the userPrincipalName property, but fall back to mail
            user.email = graphUser.userPrincipalName || graphUser.mail;
        }
        return user;
    }


    loadRoles(): void {
        const roleOptions = {
            AuthenticatedEmailAddress: this.user.email
        } as Authorization_GetRoles_Options;

        this.Authorization_GetRoles(roleOptions).toPromise()
            .then(roles => this.user.roles = roles);
    }

    public async getRolesWithRefresh(): Promise<any[]> {
        const freshUser = await this.getUser();

        if (freshUser == null) {
            return null;
        }

        const wrappedEmail = {
            AuthenticatedEmailAddress: freshUser.email
        };

        if (this.user!.email == freshUser.email && this.user!.roles && this.user!.roles.length > 0){
            return Promise.resolve(this.user!.roles);
        }

        return this.Authorization_GetRoles(wrappedEmail)
            .toPromise()
            .then(roles => {

                this.user.roles = roles;
                this.user.email = freshUser.email;
                this.user.displayName = freshUser.displayName;
              
                return roles;
            });
    }

    public async reload(): Promise<User> {
        return await new Promise((resolve, reject) => {
            if (!!this.user && !!this.user.roles) {
                resolve(this.user);
            }
            const allAccounts = this.msalService.instance.getAllAccounts();
            const accountExists = allAccounts.length > 0;
            if (accountExists) {
                const user = this.initializeUser(allAccounts[0]);
                resolve(user);
            }
            else {
                reject(null);
            }
        });
    }

    public UserInRole(roleEnum: RoleValue): boolean {
        if (!this.user) {
            return false;
        }
        var index: number = this.user.roles.findIndex(role => +role.AuthorizationRoleID == roleEnum);
        return (index > -1 ? true : false);
    }

    public UserInRoles(roleValues: RoleValue[]): boolean {
        if (!this.user) {
            return false;
        }
        const allowedStatusRoles = this.user.roles.filter(role => roleValues.includes(+role.AuthorizationRoleID));
        return (allowedStatusRoles.length > 0);
    }
}

